import React from 'react';

import { AboutPage } from '@kali/theme/lib/pages/about/page';
import Layout from '../components/layout';

export default function AboutRoute() {
  return (
    <Layout title="About">
      <AboutPage />
    </Layout>
  );
}
